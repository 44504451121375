* {
  margin: 0;
  padding: 0;
  font-family: "Libre Franklin", sans-serif;
}

body {
  font-family: "Libre Franklin", sans-serif;
  font-size: 16px;
  font-weight: 400;
}

.testimonialSlider .slick-slider .slick-dots {
  bottom: 20px;
}
.mt100 {
  margin-top: 100px;
}
.heading {
  font-size: 28px;
  font-weight: 500;
  color: #000;
  line-height: 38px;
  position: relative;
}
.commonBtn {
  width: 190px;
  padding: 10px;
  border-radius: 10px;
  margin-right: 20px;
  margin-top: 10px;
  --bs-btn-color: #fff;
  --bs-btn-bg: #fc4102 !important;
  --bs-btn-border-color: #fc4102 !important;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #fc4102 !important;
  --bs-btn-hover-border-color: #fc4102 !important;
  --bs-btn-focus-shadow-rgb: 49, 132, 253;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #fc4102 !important;
  --bs-btn-active-border-color: #fc4102 !important;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #fc4102 !important;
  --bs-btn-disabled-border-color: #fc4102 !important;
}
.desk_hide {
  display: none;
}
.footer_btn button {
  width: 100%;
  padding: 10px;
  font-size: 13px;
  font-weight: 500;
}

.testimonialSlider .slick-slide {
  padding: 10px;
}

.testimonialSlider .slick-slider .slick-dots li button::before {
  content: "\f111";
  font-family: "FontAwesome";
}

.testimonialSlider .slick-dots li.slick-active button::before {
  color: #fc4102;
}

.testimonialSlider .slick-dots li button::before {
  color: #fc4102;
}

.carouselSlider .slick-next::before,
.slick-prev::before {
  font-size: 20px;
  line-height: 1;
  opacity: 0.75;
  color: #fc4102 !important;
}
.carouselSlider .slick-next::before,
.slick-prev::before {
  font-size: 20px;
  line-height: 1;
  opacity: 0.75;
  color: #fc4102 !important;
}

.carouselSlider .slick-slide {
  padding: 10px;
}

.slick-next::before {
  content: "\f054";
  font-family: "FontAwesome";
}

.slick-prev::before {
  content: "\f053";
  font-family: "FontAwesome";
}

.footer_logo {
  width: 130px;
}
.modal-backdrop.show {
  z-index: -1;
}
.modal {
  background-color: #00000082;
  z-index: 9999;
}
.modal-content {
  background: #ffede7;
  border-radius: 20px;
}
.close_btn {
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 35px;
  height: 35px;
  border-radius: 50%;
  position: absolute;
  right: -14px;
  top: -13px;
}
.close_btn .btn-close {
  margin: 0 !important;
  width: auto !important;
  height: auto !important;
  padding: 6px !important;
}
.mt40 {
  margin-top: 30px;
}
.modal-header {
  border-bottom: none !important;
  padding: 11px !important;
}
.modal-dialog {
  width: 404px !important;
}
.modal-body {
  padding: 23px 23px 31px !important;
}
.modal_contact_details {
  margin-top: 20px;
}
.modal_contact_details a {
  display: block;
  color: #222;
  text-decoration: none;
  font-size: 13px;
}
.btn-close:focus {
  box-shadow: none !important;
}
.modal_title {
  font-size: 22px;
  font-weight: 500;
}
.modal_input input {
  padding: 8px !important;
  font-size: 13px;
}
.modal_btn button {
  padding: 7px !important;
  font-size: 13px;
}
.modal_input select {
  padding: 9px 9px 9px 4px !important;
  font-size: 13px;
}
.error_message {
  color: red;
  font-size: 11px;
  margin-left: 3px;
  position: absolute;
}
.footer_link {
  text-decoration: none;
  color: #222;
}

.testimonialSlider .slick-slider .slick-dots li {
  margin: 0;
}
.dropdown-menu {
  --bs-dropdown-link-active-bg: orangered !important;
}
.p0 {
  margin-top: 31px;
}

@media screen and (max-width: 768px) {
  .p0 {
    margin-top: 20px;
  }
  .modal-dialog {
    width: 94% !important;
  }
  .modal_item {
    margin-top: 0;
  }
  .mob_hide {
    display: none;
  }
  .desk_hide {
    display: block;
  }
  .w65 {
    width: 65%;
  }
  .card-title {
    font-size: 16px;
    margin-bottom: 0;
  }
  .card-text {
    font-size: 13px;
  }
  .mt0 {
    margin-top: 0 !important;
  }
  .mt100 {
    margin-top: 40px;
  }
  .footer_logo {
    width: 100px;
  }
  .heading {
    font-size: 18px;
    line-height: 28px;
    margin-bottom: 20px;
  }
  .navbar-nav .dropdown-menu {
    margin-left: -10px;
    /* padding-left: 11px;
    padding-right: 11px; */
    border-top: 1px solid #fc41023d;
    border-right: none;
    border-left: none;
    border-bottom: none;
    border-radius: 0;
  }
  .footer_col p:nth-child(1) {
    margin-top: 31px;
  }
  .navbar-nav .dropdown-menu li {
    padding: 3px 0;
  }
  .dropdown-toggle {
    padding-bottom: 0;
  }
  .nav-link {
    font-size: 14px;
  }
  .dropdown-item {
    font-size: 14px;
  }
  .navbar-toggler {
    border: none !important;
    padding: 0 !important;
  }
  .navbar-toggler:focus {
    box-shadow: none !important;
  }
}
