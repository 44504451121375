.idea_bg {
  position: relative;
  width: 100%;
  height: 470px;
  background-image: url("../../data/the-idea.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
}
.cloudNineRooms {
  text-align: end;
}
.cloudNineRooms img {
  width: 75%;
}
.heading {
  font-size: 28px;
  font-weight: 500;
  color: #000;
  line-height: 38px;
  position: relative;
}
.numberBtn {
  width: 190px;
  padding: 10px;
  border-radius: 10px;
  margin-right: 20px;
  margin-top: 10px;
  --bs-btn-color: #fff;
  --bs-btn-bg: #fc4102 !important;
  --bs-btn-border-color: #fc4102 !important;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #fc4102 !important;
  --bs-btn-hover-border-color: #fc4102 !important;
  --bs-btn-focus-shadow-rgb: 49, 132, 253;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #fc4102 !important;
  --bs-btn-active-border-color: #fc4102 !important;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #fc4102 !important;
  --bs-btn-disabled-border-color: #fc4102 !important;
}

@media screen and (max-width: 768px) {
  .idea_bg {
    height: 386px;
  }
  .heading {
    font-size: 18px;
    line-height: 28px;
    margin-top: 10px;
    margin-bottom: 0;
  }
  .cloudNineRooms {
    text-align: center;
  }
  .cloudNineRooms img {
    width: 100%;
  }
  .numberBtn {
    width: 108px;
    padding: 6px;
    font-size: 13px;
  }
}
