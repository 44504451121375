.gallery {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-auto-rows: 323px; /* Adjust height as needed */
    grid-gap: 20px;
  }
  
  .galleryItem {
    width: 100%;
    height: 100%;
    position: relative;
  }
  
  .galleryItem:hover .galleryImg img {
    transform: scale(1.5);
  }
  
  .galleryImg {
    width: 100%;
    height: 100%;
    overflow: hidden;
    border-radius: 10px;
  }
  
  .galleryImg img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: 50% 50%;
    cursor: pointer;
    transition: .5s ease-in-out;
    border-radius: 10px;
  }
  
  @media (max-width: 480px) {
    .gallery {
      grid-template-columns: repeat(2, 1fr);
      grid-auto-rows: 150px; /* Adjust height as needed */
      grid-gap: 15px;
    }
  }

/* .gallery {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    grid-auto-rows: 323px 323px;
    grid-gap: 20px;
    grid-auto-flow: dense;
}

.galleryItem {
    width: 100%;
    height: 100%;
    position: relative;
}

.galleryItem:hover .galleryImg img {
    transform: scale(1.5);
}

.galleryImg {
    width: 100%;
    height: 100%;
    overflow: hidden;
    border-radius: 10px;
}

.galleryImg img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: 50% 50%;
    cursor: pointer;
    transition: .5s ease-in-out;
    border-radius: 10px;
}

.w1 {
    grid-column: span 1;
}

.w2 {
    grid-column: span 2;
}

.w3 {
    grid-column: span 3;
}

.w4 {
    grid-column: span 4;
}

.w5 {
    grid-column: span 5;
}

.w6 {
    grid-column: span 6;
}

@media (max-width: 768px) {
    .gallery {
        grid-template-columns: repeat(8, 1fr);
        grid-auto-rows: 150px 150px;
        grid-gap: 15px;
    }

    .w2 {
        grid-column: span 4;
    }
    
    .w3 {
        grid-column: span 4;
    }
    
    .w4 {
        grid-column: span 4;
    }

    .w12 {
        grid-column: span 8;
    }

    .rowSpan{
        grid-row: span 2;
    }
} */