.footer_text {
  font-size: 15px;
  margin-top: 10px;
}
.footer_heading {
  font-size: 17px;
  position: relative;
}
.footer_heading::after {
  content: "";
  position: absolute;
  width: 39%;
  height: 3px;
  background-color: #fc4102;
  left: 0;
  bottom: -10px;
}
.first_p {
  margin-top: 31px;
}
.footer_link {
  text-decoration: none;
  color: #222;
}
.footer_socials {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.footer_socials div:nth-child(3) {
  width: 20%;
  display: flex;
  justify-content: space-between;
}
.footer_socials div:nth-child(3) img {
  width: 34px;
  height: 34px;
}
.bottom_footer {
  width: 100%;
  height: 5px;
  background-color: #fc4102;
  margin-top: 50px;
}
@media screen and (max-width: 768px) {
  .footer_heading {
    margin-top: 10px;
    font-size: 15px;
  }
  .first_p {
    margin-top: 20px;
  }
  p {
    font-size: 13px;
    margin-bottom: 8px;
  }
  .footer_socials {
    flex-wrap: wrap;
  }
  .footer_socials .footer_link {
    font-size: 13px;
    font-weight: 500;
  }
  .footer_socials div:nth-child(3) {
    width: 35%;
    margin-top: 10px;
  }
  .footer_socials div:nth-child(3) img {
    width: 27px;
    height: 27px;
  }
  .bottom_footer {
    margin-top: 30px;
  }
}
