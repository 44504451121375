.CarouselBox {
  width: 433.33px;
  border-radius: 20px;
  background: #fff;
  box-shadow: 0px 0px 4px 0px rgba(174, 174, 174, 0.15);
  transition: 0.3s;
}

.CarouselBox:last-child {
  margin-right: 0 !important;
}

.CarouselImage {
  border-radius: 20px 20px 0px 0px;
  height: 300px;
  width: 100%;
}

.CarouselText {
  padding: 20px;
}

.CarouselText h4 {
  font-size: 20px;
  line-height: 30px;
  font-weight: 500;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  margin-bottom: 8px;
}

.CarouselText p {
  font-size: 17px;
  line-height: 24px;
  font-weight: 400;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  margin-bottom: 20px !important;
}

.CarouselText .loction {
  color: #fc4102;
  margin-right: 8px;
}

.exploreBtn {
  margin-right: 10px;
  --bs-btn-color: #fff;
  --bs-btn-bg: #fc4102 !important;
  --bs-btn-border-color: #fc4102 !important;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #fc4102 !important;
  --bs-btn-hover-border-color: #fc4102 !important;
  --bs-btn-focus-shadow-rgb: 49, 132, 253;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #fc4102 !important;
  --bs-btn-active-border-color: #fc4102 !important;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #fc4102 !important;
  --bs-btn-disabled-border-color: #fc4102 !important;
}

.visitBtn {
  --bs-btn-color: #fc4102 !important;
  --bs-btn-border-color: #fc4102 !important;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #fc4102 !important;
  --bs-btn-hover-border-color: #fc4102 !important;
  --bs-btn-focus-shadow-rgb: 13, 110, 253;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #fc4102 !important;
  --bs-btn-active-border-color: #fc4102 !important;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fc4102 !important;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #fc4102 !important;
  --bs-gradient: none;
}

@media screen and (max-width: 768px) {
  .CarouselText h4 {
    font-size: 16px !important;
    line-height: 19px !important;
    font-weight: 600 !important;
  }
  .CarouselText {
    padding: 10px 10px 20px 10px;
  }
  .CarouselText p {
    font-size: 13px;
    line-height: 16px;
    margin-bottom: 12px !important;
  }
  .CarouselImage {
    height: 225px;
    width: 100%;
  }

  .exploreBtn {
    font-size: 13px;
  }
  .visitBtn {
    font-size: 13px;
  }
  .CarouselBox {
    box-shadow: 0px 0px 4px 0px rgba(174, 174, 174, 0.95);
    width:300px !important;
  }
}