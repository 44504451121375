.homepageMain {
    overflow: hidden;
}

.homepageImage {
    width: 100%;
    height: 735px;
    background-image: url("../../data/homepage-banner.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
}

.bannerSection {
    position: absolute;
    top: 250px;
    left: 120px;
}

.mainHeading ul {
    font-size: 17px;
    font-weight: 500;
    display: flex;
    list-style: none;
    position: relative;
    padding: 0px;
}

.mainHeading ul li {
    margin-right: 40px;
    position: relative;
}

.mainHeading ul li:after {
    width: 8px;
    height: 8px;
    background: #000;
    border-radius: 100%;
    position: absolute;
    content: "";
    display: block;
    top: 10px;
    right: -24px;
}

.mainHeading ul li:last-child::after {
    display: none;
}

.mainHeading h1 {
    font-size: 50px;
    font-weight: 500;
    line-height: 66px;
    color: #fff;
    text-transform: capitalize;
    margin-top: 10px;
}

.mainBtn {
    display: flex;
}

.numberBtn {
    width: 190px;
    padding: 10px;
    border-radius: 10px;
    margin-right: 20px;
    margin-top: 0px;
    --bs-btn-color: #fff;
    --bs-btn-bg: #fc4102 !important;
    --bs-btn-border-color: #fc4102 !important;
    --bs-btn-hover-color: #fff;
    --bs-btn-hover-bg: #fc4102 !important;
    --bs-btn-hover-border-color: #fc4102 !important;
    --bs-btn-focus-shadow-rgb: 49, 132, 253;
    --bs-btn-active-color: #fff;
    --bs-btn-active-bg: #fc4102 !important;
    --bs-btn-active-border-color: #fc4102 !important;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --bs-btn-disabled-color: #fff;
    --bs-btn-disabled-bg: #fc4102 !important;
    --bs-btn-disabled-border-color: #fc4102 !important;
}

.numberBtn a {
    text-decoration: none;
    color: #fff;
}

.telephone {
    margin-right: 7px;
}

.visitBtn {
    width: 190px;
    padding: 10px;
    border-radius: 10px;
    margin-right: 20px;
    margin-top: 10px;
    font-weight: 500;
    --bs-btn-color: #FC4102 !important;
    --bs-btn-border-color: #FC4102 !important;
    --bs-btn-hover-color: #fff;
    --bs-btn-hover-bg: #FC4102 !important;
    --bs-btn-hover-border-color: #FC4102 !important;
    --bs-btn-focus-shadow-rgb: 13, 110, 253;
    --bs-btn-active-color: #fff;
    --bs-btn-active-bg: #FC4102 !important;
    --bs-btn-active-border-color: #FC4102 !important;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --bs-btn-disabled-color: #FC4102 !important;
    --bs-btn-disabled-bg: transparent;
    --bs-btn-disabled-border-color: #FC4102 !important;
    --bs-gradient: none;
    background: #fff;
    --bs-bg-opacity: 1;
    background-color: rgba(var(--bs-white-rgb), var(--bs-bg-opacity)) !important;
}

.ourProperties {
    margin-top: 100px;
}

.mainHeading h2 {
    font-size: 28px;
    font-weight: 500;
    color: #000;
    line-height: 38px;
}

.mainHeading p{
    margin-bottom: 40px;
}

.mainHeading h2 span {
    color: #fc4102;
}

.threePoints {
    margin-top: 120px;
    position: relative;
}

.threePoints .mainHeading h2 {
    font-size: 30px;
    font-weight: 500;
    line-height: 55px;
    margin-bottom: 20px;
}

.threeImage {
    /* position: absolute; */
    z-index: -1;
    margin-top: -250px;
}

.threeImage img {
    height: 786px;
    width: 100%;
    object-fit: cover;
}

.threePointsPattern {
    position: absolute;
    top: -100px;
    left: 0;
    z-index: -3;
}

.threePointsPattern img {
    height: 450px;
}

.amenitiesSection {
    margin-top: 100px;
    position: relative;
}

.amenitiesItem {
    position: relative;
}

.amenitiesImg {
    position: relative;
    z-index: -1;
}

.amenitiesImg img {
    height: 330px;
    width: 100%;
}

.amenitiesText {
    position: absolute;
    top: 50px;
    left: 20px;
}

.amenitiesText h4 {
    font-size: 18px;
    font-weight: 600;
    color: #fff;
    line-height: 28px;
    -webkit-transition: 0.5s;
    transition: all 0.5s;
    text-transform: capitalize;
}

.amenitiesContainer {
    display: flex;
    margin-top: 60px;
}

.amenitiesBox {
    flex: 1;
    text-align: center;
}

.amenitiesBox img {
    height: 50px;
}

.amenitiesBox h4 {
    font-size: 18px;
    color: #000;
    font-weight: 400;
    line-height: 28px;
    margin-top: 10px;
}

.amenitiesPattern {
    position: absolute;
    top: -100px;
    right: 0;
    z-index: -3;
}

.amenitiesPattern img {
    height: 450px;
}

.testimonials {
    position: relative;
    background-image: url(../../data//testimonials-background.png);
    padding-top: 100px;
    padding-bottom: 100px;
    margin-top: 100px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
}

.testimonials .mainHeading h2 {
    margin-bottom: 30px;
}

.testimonialsSvg {
    position: absolute;
    top: 290px;
    right: 40px;
}

.testimonialsSvg img {
    height: 220px;
}

.pictureGallery {
    margin-top: 100px;
}

.pictureGallery .mainHeading h2 {
    text-align: center;
    margin-bottom: 10px;
}

.pictureGallery .mainHeading p {
    text-align: center;
}

/* .pictureGallery .gallery {
    margin-top: 40px;
} */

.contactFormSection {
    margin-top: 100px;
    padding: 100px 0px;
    position: relative;
    background-image: url(../../data//contact-background.png);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
}

.contactForm {
    align-items: center;
}

@media (max-width: 768px) {
    .homepageImage {
        height: 500px;
    }

    .property_row {
        display: flex;
        flex-wrap: nowrap;
        overflow-x: scroll;
        padding-bottom: 2px;
        margin-top: 20px;
        padding-right: 30px;
    }

    .property_row::-webkit-scrollbar {
        display: none;
        -webkit-display: none;
    }

    .property_row {
        display: flex;
        flex-wrap: nowrap;
        overflow-x: scroll;
        padding-bottom: 2px;
        margin-top: 20px;
    }

    .property_row::-webkit-scrollbar {
        display: none;
        -webkit-display: none;
    }

    .bannerSection {
        top: 160px;
        left: 20px;
    }

    .mainHeading ul {
        font-size: 14px;
    }

    .bannerSection {
        top: 160px;
        left: 20px;
    }

    .mainHeading ul {
        font-size: 14px;
    }

    .mainHeading ul li {
        margin-right: 30px;
    }

    .mainHeading ul li:after {
        width: 7px;
        height: 7px;
        top: 7px;
        right: -15px;
    }

    .mainHeading h1 {
        font-size: 30px;
        line-height: 50px;
    }

    .numberBtn {
        width: 130px;
        padding: 6px;
        font-size: 13px;
        text-decoration: none;
    }

    .mainHeading h1 {
        font-size: 30px;
        line-height: 50px;
    }

    .numberBtn {
        width: 140px;
        padding: 6px;
        font-size: 13px;
        text-decoration: none;
        margin-right: 10px;
    }

    .visitBtn {
        width: 130px;
        padding: 6px;
        font-size: 13px;
    }

    .mainHeading h2 {
        font-size: 18px;
        line-height: 28px;
        margin-bottom: 0;
    }

    .amenitiesSection {
        margin-top: 40px;
    }

    .pictureGallery {
        margin-top: 40px;
    }

    .contactFormSection {
        padding: 0 0 40px;
    }

    .mob_amenities {
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        flex-wrap: wrap;
    }

    .amenity_box {
        width: 39%;
        display: flex;
        align-items: center;
        margin-top: 10px;
    }

    .amenity_box div img {
        width: 20px;
    }

    .ourProperties {
        margin-top: 40px;
    }

    .threePoints {
        margin-top: 60px;
    }

    .threePointsPattern img {
        height: 160px;
    }

    .threeMobPoints {
        margin-top: 40px;
    }

    .PointOneConvenience {
        height: 170px;
        background-color: #FFEDE7;
        border-radius: 10px;
        position: relative;
        margin: 10px 0px;
    }

    .convenienceImg {
        position: absolute;
        left: -3px;
        top: -20px;
    }

    .convenienceImg img {
        height: 190px;
    }

    .PointOneText {
        display: flex;
        align-items: center;
    }

    .PointOneText h3 {
        font-size: 16px;
        font-weight: 600;
    }

    .PointTwoComfort {
        height: 170px;
        background-color: #F0FFF8;
        border-radius: 10px;
        position: relative;
        margin: 10px 0px;
        margin-top: 40px;
    }

    .PointThreeBudget {
        height: 170px;
        background-color: #F2F7FF;
        border-radius: 10px;
        position: relative;
        margin: 10px 0px;
        margin-top: 40px;
    }

    .comfortImg img {
        height: 140px;
    }

    .comfortDiv {
        display: flex;
        align-items: center;
    }

    .BudgetImg img {
        height: 140px;
    }

    .threeMobPoints p {
        font-size: 12px;
    }

    .threePoints .mainHeading h2 {
        font-size: 18px;
        font-weight: 600;
        line-height: 28px;
        text-align: center;
    }

    .threePointsPattern {
        top: -30px;
    }

    .amenitiesMobDiv {
        display: flex;
        flex-wrap: nowrap;
        overflow-x: scroll;
        margin-top: 30px;
    }

    .amenitiesMobDiv::-webkit-scrollbar {
        display: none;
    }

    .amenitiesMobItem {
        width: 65% !important;
    }

    .amenitiesImg img {
        height: 250px;
    }

    .amenitiesText h4 {
        font-size: 16px;
        line-height: 26px;
    }

    .amenitiesPattern {
        top: -20px
    }

    .amenitiesPattern img {
        height: 130px;
    }

    .testimonials {
        margin-top: 40px;
        padding-top: 40px;
        padding-bottom: 40px;
        background-position: initial;
    }

    .testimonials .mainHeading h2 {
        margin-bottom: 0px !important;
    }

    .testimonialsSvg {
        top: 310px;
        right: 10px;
    }

    .testimonialsSvg img {
        height: 130px;
    }

    .contactFormSection {
        margin-top: 40px;
        background-position: initial;
    }

    .testimonials .mainHeading h2 {
        margin-bottom: 20px;
    }

    .pictureGallery .gallery {
        margin-top: 20px;
    }
}